<template>
    <v-dialog v-model="dialog" width=800 persistent>
        <v-card>
            <v-form ref="form" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Comunicado</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-text-field
                        v-model="dat.titulo"
                        label="Título"
                        rounded
                        filled
                        dense
                    />

                    <editor
                        v-model="dat.nota"
                        api-key="5ao0kvhebp3vinnv26st7ce6tgkpakhs1xa30yiwt7w6qrc4"
                        :init="{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'lists link',
                            ],
                            toolbar:
                            'bold italic|bullist numlist|'
                        }"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="success" @click="guarda" :loading="cargando">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    props:{
        dialogModal: Boolean,
        esteMensaje:Object,
    },

    components:{
        Editor
    },

    data:()=>({
        formValid:false,
        dat:{},
        cargando:false,
        customToolbar: [["bold", "italic"], [{ list: "ordered" }, { list: "bullet" }]],
    }),

    methods:{
        async guarda(){
            this.cargando = true
            try{
                let req = await this.$http({
                    method:'POST',
                    url: '/admin/guardaMensaje',
                    params: this.dat,
                })
                this.cargando = false
                if (req.data.estado){
                    this.dialog = false
                    this.$emit('guardado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.cargando = false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        cancela(){
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (v){
                this.dat.nota = ''
                this.dat = {...this.esteMensaje}
            }
            if (!v){
                this.dat = {}
                this.$refs.form.reset()
            }
        }
    },
}
</script>

<style>

</style>