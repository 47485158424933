var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Comunicado")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancela}},[_c('v-icon',[_vm._v("mdi-close-box")])],1)],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Título","rounded":"","filled":"","dense":""},model:{value:(_vm.dat.titulo),callback:function ($$v) {_vm.$set(_vm.dat, "titulo", $$v)},expression:"dat.titulo"}}),_c('editor',{attrs:{"api-key":"5ao0kvhebp3vinnv26st7ce6tgkpakhs1xa30yiwt7w6qrc4","init":{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'lists link',
                        ],
                        toolbar:
                        'bold italic|bullist numlist|'
                    }},model:{value:(_vm.dat.nota),callback:function ($$v) {_vm.$set(_vm.dat, "nota", $$v)},expression:"dat.nota"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","loading":_vm.cargando},on:{"click":_vm.guarda}},[_vm._v(" Guardar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }